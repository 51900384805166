<template>
  <div class="bigbox">
    <!-- 智慧采购 -->
    <div class="zhfwtop">
       <div class="indeximgtopcontent">
        <div class="indexlogo"></div>
        <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span class="active2">产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <li  @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <span class="active" @click="go('/cpjjfa/bgxt')">办公协同</span>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span>用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      <a href="http://www.kingislucky.cn:81/#/home" class="enbutton">
        <img src="../.././assets/enicon.png" alt="">
        <div>Translate</div>
      </a>
    </div>

      <div class="zhfwbutton"></div>
    </div>
    <!-- 产品全景结构图 -->
    <div class="hytd">
      <div class="hytdcontent">
        <div class="hytdcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">产品全景结构图</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="hytdpng"></div>
      </div>
    </div>
    <!-- OA平台基础架构 -->
    <div class="szhzx">
      <div class="szhzxcontent">
        <div class="szhzxcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">OA平台基础架构</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="OAindeuce">OA平台采用基于分层、标准和构件等进行架构。平台架构遵循JEE标准、SOA标准、WFMC标准、W3 C fOrm标准、JSR168、WSRP等标准。OA平台架构应支持多种部署模式、多种操作系统、各种数据库和中间件，并具备完备的配置体系、接口体系和插件体系，从而支持未来的扩展空间。</div>
        <div class="szhzxpng"></div>
      </div>
    </div>
    <!-- 发展趋势 -->
    <div class="ywgzd">
      <div class="ywgzdcontent">
        <div class="ywgzdcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">发展趋势</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="ywgzdpng">
          <div class="ywgzdpngevery"><span>办公信息数字化、多媒体化</span></div>
          <div class="ywgzdpngevery"><span>办公环境网络化、国际化</span></div>
          <div class="ywgzdpngevery"><span>办公操作无纸化、无人化、简单化</span></div>
          <div class="ywgzdpngevery"><span>办公业务集成化</span></div>
        </div>
      </div>
    </div>

    <!-- 咨询热线 -->
    <!-- <zi-xun></zi-xun> -->
    <!-- 版权 -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../components/TabarBottom.vue'
import ZiXun from '../../components/ZiXun.vue'
  export default {
  components: { ZiXun, TabarBottom },
    name:'zhcg',//智慧采购
    data(){
      return{

      }
    },
    methods:{
       go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.enbutton{
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  right: 5%;
  top: 42px;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  width: 102px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
// 发展趋势
.ywgzd{
  // width: 1903px;
  height: 658px;
  margin: 0 auto;
  .ywgzdcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
      .ywgzdcontenttitle{
  width: 455px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.ywgzdpng{
  width: 1200px;
  height: 373px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .ywgzdpngevery{
    width: 580px;
    height: 158px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 1px #E5D7BF;
    border-top: 10px solid #FFA000;
    font-size: 24px;
font-family: Source Han Sans CN-Medium, Source Han Sans CN;
font-weight: 500;
color: #282A2E;
display: flex;
justify-content: center;
align-items: center;

  }
}
  }
}
// OA平台基础架构
.szhzx{
  // width: 1903px;
  height: 786px;
  margin: 0 auto;
  background: url('../.././assets/zhcg_szhzxback.png') no-repeat center;
  background-size: 100%;
  .szhzxcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    .szhzxcontenttitle{
  width: 525px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.OAindeuce{
  width: 1200px;
  height: 43px;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #282A2E;
  text-align: center;
}
.szhzxpng{
  width: 1200px;
  height: 447px;
  margin-top: 20px;
  background: url('../.././assets/bgxt_jckjback.png') no-repeat center center;
  background-size:1200px 447px;
}
  }
}
// 产品全景结构图
.hytd{
  // width: 1903px;
  height: 910px;
  margin: 0 auto;
  .hytdcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
        .hytdcontenttitle{
  width: 506px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.hytdpng{
  width: 1200px;
  height: 608px;
  background: url('../.././assets/xtbg_cpjgpng.png') no-repeat center;
}
  }
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../.././assets/bangongxietong.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
   width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      // text-align: center;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
